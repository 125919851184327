var jquery = require("jquery");
window.$ = window.jQuery = jquery; // notice the definition of global variables here

import 'slick-carousel'

import './../node_modules/slick-carousel/slick/slick.css';
import './scss/_carousel.scss';

(function () {
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.slider-nav',
        lazyLoad: 'progressive',
        infinite: true
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        lazyLoad: 'progressive',
        dots: true,
        centerMode: true,
        focusOnSelect: true,
        infinite: true
    });
})()